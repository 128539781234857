import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import SimpleNewsletterForm from "../../components/common/SimpleNewsletterForm";
import { Button } from "gatsby-material-ui-components";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: `-${theme.spacing(1)}px`,
    color: theme.palette.custom.additional4,
    minHeight: 78
  },
  tmp: {
    padding: theme.spacing(2),
  },
}));

const Request = ({ langKey }) => {
  const [requested, setRequested] = useState(false);
  const classes = useStyles();

  const handleClick = () => setRequested(true);

  return requested ? (
    <div className={classes.tmp}>
      <SimpleNewsletterForm
        whiteButton   
        langKey={langKey}        
        large={false}
        successMessage={langKey === "fr" ? "Demande envoyée" : "Request sent"}
      />
    </div>
  ) : (
    <Button className={classes.root} onClick={handleClick}>
      {langKey === "fr"
        ? "Recevoir une documentation"
        : "Request the reference sheet"}
    </Button>
  );
};

Request.propTypes = {
  langKey: PropTypes.string.isRequired,
};

export default Request;
